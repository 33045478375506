export async function getApi(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/sections?type=slug&value=${param}&get_section=yes&image=yes&post=yes&file=yes&gallery=no`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}



export async function getChildApi(id,value) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/child-pages?page_id=${id}&value=${value}&get_section=yes&image=yes&post=yes&gallery=yes`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getSettingApi(params) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/settings-data?id=${params}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getMedia(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/news-list?category_id=${param}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getMediaDetails(param,slug) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/blog-data?type=${param}&value=${slug}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getCategory(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/all-category?type=${param}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

export async function getChildPage(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/child-pages?page_id=${param}&image=yes&post=yes&file=no&gallery=no&sections=yes`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}
export async function getDestinationUniversity(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/university-by-cats?category=${param}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getUniversity(country, study, course ) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/university-list?country=${country}&study=${study}&course=${course}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getSearch(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/search?keyword=${param}`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getSearchCourse(param) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/search-course?keyword=${param}`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


export async function getCourse(country, study) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/course-list?country=${country}&study=${study}`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

export async function getSchools(country, area) {
    const response = await fetch(
        `https://project.bestinbd.com/projects/web/2406MEM/api/get-req-data/school-list?country=${country}&area=${area}`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}