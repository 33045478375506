import React, {useEffect, useRef, useState} from "react";
import {text} from "@/styles/globalStyleVars";
import Button from "@/components/common/ui/Button";
import reactHtmlParser from "react-html-parser";
import {gsap} from "gsap";

const ButtonComponent = ({region,onClick ,isToggled,handleToggle,isToggledLanguage ,searchItemRef, selectedRegion}) => {

    const searchClickRef = useRef();
    let countryOptions = [];
    let studyArray = region
    // Check if universityArray is defined and not empty
    if (studyArray && studyArray.length > 0) {
        countryOptions = studyArray.map(e => ({
            value: e?.category_data?.slug,
            label: reactHtmlParser(e?.category_data?.title),
            icon: e?.images?.list?.[0]?.full_path,
            short: e?.category_data?.description,
            id: e?.category_data?.id,
        }));
    }



    useEffect(() => {
        // Function to close the search single-mir-parts


        // Open the search single-mir-parts when the search button is clicked
        searchClickRef?.current?.addEventListener('click', () => {
            if (!searchItemRef.current?.classList.contains('search-open')) {
                searchItemRef.current?.classList.add('search-open');
                document.querySelector('body').classList.add('menu-is-open');
                gsap.to(searchItemRef.current, {
                    display: 'flex',
                });
                gsap.to(searchItemRef.current, {
                    duration: 0.5,
                    height: 180,
                    opacity: 1,
                    overflow: 'visible',
                });
                gsap.to(searchItemRef.current?.querySelector('.container'), {
                    opacity: 1,
                    delay: 0.2,
                });
                document.querySelector('body').classList.add('open_menu');

                // Add a mousedown event listener to the document to check if the click occurred within the search single-mir-parts, input field, or the excluded element
                document.addEventListener('mousedown', (e) => {
                    if (
                        !searchItemRef.current.contains(e.target) &&
                        !searchClickRef.current.contains(e.target) &&
                        !e.target.classList.contains('search-desktop') &&
                        !e.target.classList.contains('search-open')
                    ) {
                        closeSearchBox();
                    }
                });



            } else {
                // closeSearchBox();
            }
        });


    }, []);


    const closeSearchBox = () => {
        if (searchItemRef.current?.classList.contains('search-open')) {
            document.querySelector('body').classList.remove('open_menu');
            document.body.classList.remove('menu-open');
            searchItemRef.current.classList.remove('search-open');
            gsap.to(searchItemRef.current, {
                duration: 0.4,
                height: 0,
                opacity: 0,
                overflow: 'hidden',
            });
            gsap.to(searchItemRef.current.querySelector('.container'), {
                opacity: 0,
            }, '-=.4');
            gsap.to(searchItemRef.current, {
                display: 'none',
            });

        }
    };

    const [isToggledLanguageRegion, setIsToggledLanguageRegion] = useState(selectedRegion);

    // initialize landing popup
    useEffect(() => {
        if (localStorage?.getItem('region')) {
            setIsToggledLanguageRegion(JSON.parse(localStorage?.getItem('region'))?.value)
        }else{
            setIsToggledLanguageRegion('global')

        }


    }, [selectedRegion,region]);


    const handleToggleLanguage = (e) => {
        // Invoke the onChange callback with the selected value
        setIsToggledLanguageRegion(e?.value)
        if (onClick) {
            onClick(e);
        }

    };




    return (
        <div className="main-menu__button">
            <Button
                text={"Contact Us"}
                src={"/contact-us"}
            />
            <div className="search" ref={searchClickRef}>
                <svg id="Button_-_Search" data-name="Button - Search" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g id="Ellipse_16" data-name="Ellipse 16" fill="none" stroke="#fff" strokeWidth="1">
                        <circle cx="20" cy="20" r="20" stroke="none"/>
                        <circle cx="20" cy="20" r="19.5" fill="none"/>
                    </g>
                    <circle id="Ellipse_19" data-name="Ellipse 19" cx="20" cy="20" r="0" fill="#fb5400"/>
                    <g id="Search" transform="translate(8 8)">
                        <path id="Path_9364" data-name="Path 9364" d="M11,19a8,8,0,1,1,8-8A8,8,0,0,1,11,19ZM11,5a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z" fill="#fff"/>
                        <path id="Path_9365" data-name="Path 9365" d="M20,21a1,1,0,0,1-.707-.293l-4-4a1,1,0,1,1,1.414-1.414l4,4A1,1,0,0,1,20,21Z" fill="#fff"/>
                    </g>
                </svg>

            </div>
            <div className={`migration-button ${isToggled ? 'ON' : 'OFF'}`}>
                <ul className={'first_button_hover_migrate_button'} onClick={handleToggle}>


                    {
                        countryOptions?.length > 0 &&
                        countryOptions?.map((e, index) => {
                            return (
                                <li key={index}
                                    onClick={() => handleToggleLanguage(e)}
                                    className={isToggledLanguageRegion === e?.value  ?  'active' : ''}
                                >

                                    {
                                        e?.value === 'global' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <g id="Bd" transform="translate(-1226 -44)">
                                                    <circle id="Ellipse_17" data-name="Ellipse 17" cx="20" cy="20" r="20" transform="translate(1226 44)" fill="#fff"/>
                                                    <circle id="Ellipse_18" data-name="Ellipse 18" cx="20" cy="20" r="0" transform="translate(1226 44)" fill="#fb5400"/>
                                                    <text id="Bd-2" data-name="Bd" transform="translate(1236 69)" fill="#000" strokeLinecap="16" strokeLinecap="Graphik-Medium, Graphik" fontWeight="500">
                                                        <tspan x="0" y="0">Gl</tspan>
                                                    </text>
                                                </g>
                                            </svg> :

                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <g id="Au" transform="translate(-1226 -44)">
                                                    <circle id="Ellipse_17" data-name="Ellipse 17" cx="20" cy="20" r="20" transform="translate(1226 44)" fill="#fff"/>
                                                    <circle id="Ellipse_18" data-name="Ellipse 18" cx="20" cy="20" r="0" transform="translate(1226 44)" fill="#fb5400"/>
                                                    <text id="Au-2" data-name="Au" transform="translate(1236 69)" fill="#000" strokeLinecap="16" strokeLinecap="Graphik-Medium, Graphik" fontWeight="500">
                                                        <tspan x="0" y="0">Au</tspan>
                                                    </text>
                                                </g>
                                            </svg>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
};

export default ButtonComponent;
