"use client";
import StyledComponentsRegistry from "@/lib/registry";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "@/styles/globalStyle";
import {usePathname,} from "next/navigation";
import React, {Suspense, useEffect, useState} from "react";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {FadeUp, ParallaxX, ParallaxXR, ParallaxXX, splitLeft, SplitUp,} from "@/components/common/animations/TextAnimations";
import StickyButton from "@/components/common/StickyButton";
import useDeviceType from "@/components/hooks/useDeviceType";
import ErrorBoundary from "@/components/ErrorBoundary";
import {getSettingApi} from "@/api";
import {useSmoothScroll} from "@/components/hooks/useSmoothScroll";
import {usePageClass} from "@/components/hooks/usePageClass";
import SEO from "@/components/hooks/SEO";
import HeaderContent from "@/components/common/globals/HeaderContent";
import ModalContent from "@/components/common/globals/ModalContent";

// import css
import "./global.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import CustomContextMenu from "@/components/common/CustomContextMenu";
import PageTransition from "@/components/common/animations/PageTransition";
import Footer from "@/components/common/globals/Footer";
import {toast} from "sonner";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

export default function RootLayout({ children }) {
  const pathName = usePathname();
  const isMobile = useDeviceType();
  const [showSetting, setShowSetting] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showRegion, setShowRegion] = useState('');

  // initialize smooth scroll
  const el = useSmoothScroll(isMobile);
  usePageClass(pathName);

  // Combined effect to handle region and notice logic
  useEffect(() => {
    const regionData = localStorage?.getItem('region');
    if (regionData) {
      const region = JSON.parse(regionData);
      setShowRegion(region);
    }

    setShowNotice(true);

    const fetchSettingData = async (regionId = '') => {
      try {
        const getSettingData = await getSettingApi(regionId);
        setShowSetting(getSettingData);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    };

    const initializeSettings = async () => {
      if (regionData) {
        const cooke = JSON.parse(regionData);
        setShowRegion(cooke);

        if (cooke?.id) {
          await fetchSettingData(cooke.id);
        } else {
          await fetchSettingData();
        }
      } else {
        await fetchSettingData();
      }
    };

    // Call the async function to initialize settings
    initializeSettings();
  }, [pathName]);

  // const closeNotice = async (e) => {
  //   setShowLoader(true);
  //   gsap.to('.notice_modal', {
  //     opacity: 0,
  //     y: -50,
  //     ease: 'power2.out',
  //   });
  //   setShowNotice(false);
  //   setTimeout(() => {
  //     setShowLoader(false);
  //   }, 500);
  //
  //   const regionData = localStorage?.getItem('region');
  //   if (regionData) {
  //     const cooke = JSON.parse(regionData);
  //     setShowRegion(cooke);
  //
  //     if (cooke) {
  //       try {
  //         const getSettingData = await getSettingApi(cooke?.id);
  //         setShowSetting(getSettingData);
  //       } catch (error) {
  //         console.error("Failed to fetch settings:", error);
  //       }
  //     }
  //     else{
  //       toast.info('Please Select a Region First')
  //     }
  //   }
  //   else{
  //     toast.info('Please Select a Region First')
  //   }
  // };

  const closeNotice = async (e) => {
    const regionData = localStorage?.getItem('region');

    // Check if region data exists
    if (!regionData) {
      setShowLoader(false);  // Hide the loader
      toast.info('Please Select a Region First');  // Show the info message
      return;  // Exit the function to prevent further execution
    }

    // Proceed with the closing animation and further logic if region data is present
    setShowLoader(true);
    gsap.to('.notice_modal', {
      opacity: 0,
      y: -50,
      ease: 'power2.out',
    });
    setShowNotice(false);

    setTimeout(() => {
      setShowLoader(false);
    }, 500);

    const cooke = JSON.parse(regionData);
    setShowRegion(cooke);

    if (cooke) {
      try {
        const getSettingData = await getSettingApi(cooke?.id);
        setShowSetting(getSettingData);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    }
  };


  // initialize animation
  splitLeft();
  ParallaxX();
  ParallaxXR();
  FadeUp();
  SplitUp();
  ParallaxXX()


  return (
      <html lang="en">
      <SEO
          title="Maximus Education and Migration"
          description="Maximus Education &amp; migration provides wide range of services for students to study in Melbourne including education consultation,visa &amp; migration services."
          keywords="keyword1, keyword2, keyword3"
          author="Your Name or Company Name"
          url={`https://www.yoursite.com${pathName}`}
          image="/images/static/logo.svg"
          twitterHandle="@yourTwitterHandle"
          facebookVerification="yourFacebookVerificationCode"
          googleVerification="yourGoogleVerificationCode"
          canonical={`https://www.yoursite.com${pathName}`}
      />

      <body suppressHydrationWarning={true}>
      <StyledComponentsRegistry>
        <ErrorBoundary>
          <GlobalStyle/>
          <Suspense>
            <HeaderContent forMobile={closeNotice} loader={showLoader} isMobile={isMobile} showSetting={showSetting} onClick={closeNotice} showRegion={showRegion}/>
          </Suspense>
          {
              pathName === '/' &&
              <PageTransition/>
          }
          <ModalContent showRegion={showRegion}
                        pathName={pathName}
                        showNotice={showNotice}
                        showSetting={showSetting}
                        closeNotice={closeNotice}
                        loader={showLoader}
          />
          <StickyButton/>
          {
            isMobile ?
                null :
                <CustomContextMenu/>

          }

          <div ref={el} id="smooth-wrapper" style={{opacity: 1}}>
            <div id="smooth-content">
              {children}
              <Footer key={100} regionData={showSetting} onChange={closeNotice} region={showRegion}/>
            </div>
          </div>
        </ErrorBoundary>

      </StyledComponentsRegistry>
      </body>
      </html>

  );
}
