import React, {useEffect, useRef} from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {hover, text} from "@/styles/globalStyleVars";
import Button from "@/components/common/ui/Button";
import {gsap} from "gsap";

const MenuItems = ({ pathName,handleOutsideClickMenu, isToggled, handleToggle, handleToggleSubMenu, isToggledSubmenu }) => {
    const subMenuClickRef = useRef();
    const subMenuItemRef = useRef();

    useEffect(() => {
        // Function to close the search single-mir-parts
        subMenuClickRef?.current?.addEventListener('click', () => {
            const subMenuItem = subMenuItemRef?.current;
            const isOpen = subMenuItem?.classList?.contains('menu-open');

            if (!isOpen) {
                // Open the menu
                subMenuItem?.classList.add('menu-open');
                document.querySelector('body').classList.add('menu-is-open');

                gsap.to(subMenuItem, {
                    display: 'block',
                    visibility: 'visible',
                });
                gsap.to(subMenuItem, {
                    height: 'auto',
                    opacity: 1,
                    overflow: 'visible',
                });


                gsap.to("#open-menu-1", {
                    opacity: 1,
                    y: 0,
                    delay: 0.2,
                    ease: "cubic-bezier(.22,.6,.36,1)",
                });
                gsap.to(" #open-menu-2", {
                    opacity: 1,
                    y: 0,
                    delay: 0.2,
                    ease: "cubic-bezier(.22,.6,.36,1)",
                });
                gsap.to("#open-menu-3", {
                    opacity: 1,
                    y: 0,
                    delay: 0.4,
                    ease: "cubic-bezier(.22,.6,.36,1)",
                });
                gsap.to(subMenuItem?.querySelector('.container'), {
                    opacity: 1,
                });
                document.querySelector('body').classList.add('open_menu');

                document.addEventListener('mousedown', handleOutsideClick);
            } else {
                // Close the menu
                closeMenuSub();
            }
        });
    }, []);




    function handleOutsideClick(e) {

        const subMenuItem = subMenuItemRef.current;
        if (
            !subMenuItem?.contains(e.target) &&
            !subMenuClickRef?.current?.contains(e.target) &&
            !e.target.classList.contains('menu-desktop__items__slide') &&
            !e.target.classList.contains('menu-open')
        ) {
            closeMenuSub();
            document.removeEventListener('mousedown', combinedHandler);
        }
    }
    const combinedHandler = (event) => {
        handleOutsideClick(event);
        handleOutsideClickMenu(event);
    };
    function closeMenuSub() {
        const subMenuItem = subMenuItemRef.current;
        subMenuItem?.classList?.remove('menu-open');
        document.querySelector('body')?.classList?.remove('menu-is-open', 'open_menu');

        gsap.to(subMenuItem, {
            height: 0,
            opacity: 0,
            overflow: 'hidden',
        });

        gsap.to("#open-menu-1, #open-menu-2, #open-menu-3", {
            opacity: 0,
            y: -10,
            stagger: 0.15,
            ease: "cubic-bezier(.22,.6,.36,1)",
        });

        gsap.to(subMenuItem, {
            display: 'none',
            delay: 1.1,
            visibility: 'hidden',

        });
    }

    return (
        <div className="main-menu__right_sider">
            {/* menu item  */}
            <div className="main-menu__items">
                <ul>
                    <li>
                        <Link prefetch={true}  className={pathName === "/" ? "active" : null}
                              href={"/"}>Home</Link>
                    </li>
                    <li className={`has-child `} ref={subMenuClickRef}>
                        <div className={'parent-title'}>For Students
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <g id="Arrow" transform="translate(-684 -42)">
                                    <g id="Ellipse_20" data-name="Ellipse 20" transform="translate(684 42)" fill="none" stroke="#fff" strokeWidth="1">
                                        <circle cx="8" cy="8" r="8" stroke="none"/>
                                        <circle cx="8" cy="8" r="7.5" fill="none"/>
                                    </g>
                                    <circle id="Ellipse_21" data-name="Ellipse 21" cx="8" cy="8" r="0" transform="translate(684 42)" fill={hover} opacity="1"/>
                                    <g id="Group_4834" data-name="Group 4834" transform="translate(-0.5)">
                                        <line id="Line_5" data-name="Line 5" x2="3" y2="3" transform="translate(689.5 48.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                        <line id="Line_6" data-name="Line 6" x1="3" y2="3" transform="translate(692.5 48.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                    </g>
                                </g>
                            </svg>
                        </div>

                        <div ref={subMenuItemRef} className="menu-desktop__items__slide">
                            <ul id={'open-menu-1'} className={"open-menu"}>
                                <li>
                                    <Link prefetch={true} href={"/universities"}>
                                        Universities
                                        <img
                                            src="/images/static/arrow-right-white.svg"
                                            alt="Maximus Education and Migration"
                                        />{" "}
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={true} href={"/courses"}>
                                        Courses
                                        <img
                                            src="/images/static/arrow-right-white.svg"
                                            alt="Maximus Education and Migration"
                                        />
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={true} href={"/schools"}>
                                        Schools
                                        <img
                                            src="/images/static/arrow-right-white.svg"
                                            alt="Maximus Education and Migration"
                                        />
                                    </Link>
                                </li>
                            </ul>
                            <div id={'open-menu-2'} className="menu-desktop__items__slide__subs as-service-sub">
                                <h4>Destinations</h4>
                                <ul>
                                    <li>
                                        <Link prefetch={true} href={"/destination/australia"}>
                                            Australia
                                            <img
                                                src="/images/static/arrow-right-white.svg"
                                                alt="Maximus Education and Migration"
                                            />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link prefetch={true} href={"/destination/malaysia"}>
                                            Malaysia
                                            <img
                                                src="/images/static/arrow-right-white.svg"
                                                alt="Maximus Education and Migration"
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link prefetch={true} href={"/destination/united-kingdom"}>
                                            United Kingdom
                                            <img
                                                src="/images/static/arrow-right-white.svg"
                                                alt="Maximus Education and Migration"
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div id={'open-menu-3'} className="menu-desktop__items__slide__subs as-join">
                                <ul>
                                    <li>
                                        <Link prefetch={true} href={"/ielts-pte"}>
                                            IELTS & PTE
                                            <img
                                                src="/images/static/arrow-right-revers.svg"
                                                alt="Maximus Education and Migration"
                                            /> <img
                                            src="/images/static/arrow-right-whites.svg"
                                            alt="Maximus Education and Migration"
                                        />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <Link prefetch={true} className={pathName === "/migrate-to-australia" ? "active" : null} href={"/migrate-to-australia"}>Migration</Link>
                    </li>
                    <li>
                        <Link prefetch={true} className={pathName === "/about-us" ? "active" : null} href={"/about-us"}>About Us</Link>
                    </li>
                    <li>
                        <Link prefetch={true}  className={pathName === "/news-events" ? "active" : null} href={"/news-events"}>News & Events</Link>
                    </li>
                </ul>
            </div>


        </div>
    );
};

export default MenuItems;
