"use client";
import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Link from "next/link";
import {hardGray, hover, softWhite, text, white} from "@/styles/globalStyleVars";
import Social from "@/components/common/Social";
import reactHtmlParser from "react-html-parser";
import SelectRegionFooterSingle from "@/components/common/SelectRegionFooterSingle";

const Footer = ({region, data, regionData, onChange}) => {


  let countryOptions = [];
  let studyArray = regionData?.region


  // Check if universityArray is defined and not empty
  if (studyArray && studyArray.length > 0) {
    countryOptions = studyArray.map(e => ({
      value: e?.category_data?.slug,
      label: reactHtmlParser(e?.category_data?.title),
      icon: e?.images?.list?.[0]?.full_path,
      short: e?.category_data?.description,
      id: e?.category_data?.id,
    }));
  }





  const phoneNumbers = regionData?.address?.[0]?.data?.scholarship_deadline?.split(", ");
  const emails = regionData?.address?.[0]?.data?.position?.split(", ");
  const regex = /(\+?\d[\d\s]+)\s*\((\w+)\)/;



  return (
    <StyledComponent className="footer">
      <Container>
        <Row>
          <Col lg={2} className="footer__content four">
            <ul>
              <h4>For Students</h4>
              <li>
                <Link prefetch={true}  href={"/universities"}>Universities</Link>
              </li>
              <li>
                <Link  prefetch={true} href={"/courses"}>Courses</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/schools"}>Schools</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/ielts-pte"}>IELTS & PTE</Link>
              </li>
            </ul>
          </Col>
          <Col lg={2} className="footer__content three">
          <ul>
              <h4>Destinations</h4>
              <li>
                <Link prefetch={true}  href={"/destination/australia"}>Australia</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/destination/malaysia"}>Malaysia</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/destination/united-kingdom"}>United Kingdom</Link>
              </li>

            </ul>

          </Col>
          <Col lg={2} className="footer__content two">
            <ul>
              <h4>Navigate</h4>
              <li>
                <Link prefetch={true}  href={"/"}>Home</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/about-us"}>About Us</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/migrate-to-australia"}>Migration</Link>
              </li>
              <li>
                <Link prefetch={true}  href={"/contact-us"}>Contact Us</Link>
              </li>
            </ul>

          </Col>
          <Col className="footer__content one d-flex align-content-end flex-column" lg={{span: 4, offset: 2}}>
            <SelectRegionFooterSingle selection={countryOptions} selectedValue={region} onChange={onChange}  border={white}/>
            <div className="address-wrapper">
              {
                  regionData?.address?.[0]?.data?.body &&
                  <>
                    <h4>Head office</h4>
                    <Link prefetch={true}  target={'_blank'} href={''}>
                      {reactHtmlParser(regionData?.address?.[0]?.data?.body)}
                    </Link>
                  </>
              }

              {


              }
              {
                emails?.map((e,index) => {
                  return (
                      <>
                        <Link prefetch={true}  key={index} href={`mailto:${e}`}>{reactHtmlParser(e)}</Link>
                      </>
                  )
                })

              }
              {
                phoneNumbers?.map((e, index) => {
                  const matches = e?.match(regex);

                  return (
                      <>
                        <Link prefetch={true}  key={index} href={`tel:${matches ? matches[1].trim() : e}`}>{reactHtmlParser(e)}</Link>
                      </>
                  )
                })

              }

            </div>

            <Social bg={white} iconHover={white} iconColor={text} hoverbg={hover} facebook={regionData?.address?.[0]?.data?.facebook} linkedin={regionData?.address?.[0]?.data?.linkedIn} youtube={regionData?.address?.[0]?.data?.youtube} pinterest={regionData?.address?.[0]?.data?.tikTok} instra={regionData?.address?.[0]?.data?.instagram}/>
          </Col>
        </Row>
        <Row className="footer__bottom">
          <Col md={6}>
            <div className="d-flex ">
              <img key={0} src={`https://project.bestinbd.com/projects/web/2406MEM/${regionData?.data?.copyright_logo_1}`} alt="Maximus Education & Migration"/>
              <img key={1} src={`https://project.bestinbd.com/projects/web/2406MEM/${regionData?.data?.copyright_logo_2}`} alt="Maximus Education & Migration"/>
              <img key={2} src={`https://project.bestinbd.com/projects/web/2406MEM/${regionData?.data?.copyright_logo_3}`} alt="Maximus Education & Migration"/>
              <img key={3} src={`https://project.bestinbd.com/projects/web/2406MEM/${regionData?.data?.copyright_logo_4}`} alt="Maximus Education & Migration"/>


            </div>
            <div className="footer-left_text">
              <p className={'split-up'}>{reactHtmlParser(regionData?.data?.footer_left_text)}</p>
            </div>
          </Col>
          <Col md={{span: 4, offset: 2}} className={'last-column'}>
            <Link prefetch={true}  href={'/privacy-policy'}>Privacy Policy</Link>
            <p>© {new Date().getFullYear()} Maximus Education & Migration</p>
            <p>All Rights Reserved</p>
            <Link prefetch={true}  href="https://dcastalia.com/" target="_blank">
              Site by Dcastalia
              <img src="/images/static/dc-logo.svg" alt="Dcastalia"/>
            </Link>
          </Col>

        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  background-color: ${softWhite};
  padding-top: 120px;
  border-top: 1px solid ${hardGray};
  position: relative;
  z-index: 1;

  .footer-left_text {
    p {
      display: block !important;

      a {
        display: inline-block !important;
      }
    }
  }
  .footer__content {
    display: flex;
    padding-bottom: 60px;

    ul {
      h4 {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.16px;
        color: ${hardGray};
        margin-bottom: 15px;
      }

      li {
        a {
          color: ${text};
          display: block;
          font-weight: 500;
        }

        &:not(:nth-last-child(1)) a {
          margin-bottom: 15px;
        }
      }

      &.social {
        a {
          margin-bottom: 0 !important;
        }
      }

    }

    .address-wrapper {
      margin-top: 30px;
      h4 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.16px;
        color: ${hardGray};
        margin-bottom: 10px;
      }
      a {
        margin-bottom: 15px;
        color: ${text};
        display: block;
        font-weight: 500;

        &:first-child {
          margin-bottom: 30px;
        }
      }
    }


  }

  .footer__bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 -15px;

    

    &:before {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      background-color: rgba(167, 161, 159, 0.1);
      right: 0;
      left: 0;
      width: calc(100% - 30px);
      margin: 0 auto;
    }

    .d-flex {
      gap: 20px;
      margin-bottom: 20px;
    }


    p,
    a {
      display: flex;
      align-items: center;
      font-size: 12px;
      letter-spacing: -0.12px;
      line-height: 16px;
      color: ${hardGray};

      img {
        margin-right: 20px;
      }

    }

    a {
      img {
        margin-left: 10px;
      }
    }

    .last-column {
      a {
        &:first-child {
          color: ${text};
          text-decoration: underline;
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
    }
  }

  @media(min-width: 1024px){
    .last-column{
      padding-left: 35px;
    }
  }
  @media (max-width: 992px) {
    .row:first-child {
      .one {
        order: 1;
        border-bottom: 1px solid rgba(167, 161, 159, 0.3);
        padding-bottom: 60px;
      }

      .two {
        order: 2;
        padding-top: 60px;
        padding-bottom: 60px;
      }

      .three {
        order: 3;
      }

      .four {
        order: 4;
      }
    }

    .footer__content {

    }
  }
  @media (max-width: 767px) {
    padding-top: 60px;
    .row:first-child {
      .one {
        order: 1;
        border-bottom: 1px solid rgba(167, 161, 159, 0.3);
        padding-bottom: 30px;
      }

      .two {
        order: 2;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .three {
        order: 3;
      }

      .four {
        order: 4;
      }
    }

    .footer__content {
      padding-bottom: 30px;
    }

    .last-column {
      padding-top: 30px;
    }
  }
`;

export default React.memo(Footer);
