"use client";
import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "@/components/common/ui/Button";
import Title from "@/components/common/ui/Title";
import {white} from "@/styles/globalStyleVars";
import SelectRegionFooter from "@/components/common/SelectRegionFooter";
import reactHtmlParser from "react-html-parser";

const LandingPopup = ({show, onClose, data, region}) => {


    let regionArray = data?.region
    let countryOptions = [];

    // Check if universityArray is defined and not empty
    if (regionArray && regionArray.length > 0) {
        countryOptions = regionArray.map(e => ({
            value: e?.category_data?.slug,
            label: reactHtmlParser(e?.category_data?.title),
            icon: e?.images?.list?.[0]?.full_path,
            short: e?.category_data?.description,
            id: e?.category_data?.id,

        }));


    }


    const handleClickCountry = async (e ) => {
        localStorage?.setItem('region',JSON.stringify(e));

    };


    return (
        <>
            <Modal
                animation={true}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                show={show}
                className="notice_modal"
            >

                <Modal.Body>
                    <div className={'notice_image'}>
                        <div className="logo">
                            <img alt={'Maximus Education and Migration'} src={'/images/static/logo-black.svg'}/>
                        </div>
                        <Title width={'100%'} text={'Take the first step towards success'} type={'h4'} margin={'40px 0 40px'}/>
                        <div className="region">
                            <SelectRegionFooter selectedValue={region}  selection={countryOptions} onChange={handleClickCountry}  border={white}/>
                            <p className={'note'}>Please pick your region to continue</p>
                        </div>
                        <div className="content">
                            <div onClick={onClose}>
                                <Button text={'Continue'} margin={'40px 0 0'}/>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};


export default LandingPopup;