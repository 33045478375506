import React from "react";
import Link from "next/link";

const Logo = () => (
    <div className="main-menu__logo">
        <Link prefetch={true}  href={"/"}>
            <img src="/images/static/logo.svg" alt="logo"/>
            <img src="/images/static/logo-black.svg" alt="logo"/>
        </Link>
    </div>
);

export default Logo;
