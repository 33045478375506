"use client"
import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter, usePathname } from 'next/navigation';
import {hover, softWhite, text, white} from "@/styles/globalStyleVars";

const CustomContextMenu = () => {
    const menuRef = useRef(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [menuHeight, setMenuHeight] = useState(0);
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoForward, setCanGoForward] = useState(false);
    const [forwardHistory, setForwardHistory] = useState([]);
    const router = useRouter();
    const pathname = usePathname();

    // Add a try-catch block within effects for debugging purposes
    useEffect(() => {
        try {
            const checkNavigation = () => {
                setCanGoBack(window.history.length > 1);
                setCanGoForward(forwardHistory.length > 0);
            };

            checkNavigation();
            window.addEventListener('popstate', checkNavigation);

            return () => {
                window.removeEventListener('popstate', checkNavigation);
            };
        } catch (error) {
            console.error("Error in navigation effect:", error);
        }
    }, [forwardHistory]);

    useEffect(() => {
        try {
            const handleRouteChange = (url) => {
                setForwardHistory([...forwardHistory, url]);
            };

            router.events?.on('routeChangeComplete', handleRouteChange);

            return () => {
                if (router && router.events) {
                    router.events?.off('routeChangeComplete', handleRouteChange);
                }
            };
        } catch (error) {
            console.error("Error in route change effect:", error);
        }
    }, [forwardHistory, router]);

    useEffect(() => {
        try {
            const handleClick = (e) => {
                // Close the menu if the click is outside the context menu
                if (menuRef.current && !menuRef.current.contains(e.target)) {
                    closeMenu();
                }
            }
            document.addEventListener('click', handleClick);
            document.addEventListener('contextmenu', handleContextMenu);
            return () => {
                document.removeEventListener('click', handleClick);
                document.removeEventListener('contextmenu', handleContextMenu);

            };
        } catch (error) {
            console.error("Error in document click effect:", error);
        }
    }, []);

    const handleContextMenu = (e) => {
        e.preventDefault();
        try {
            document.body.style.overflow = 'hidden';
            const { clientX: mouseX, clientY: mouseY } = e;
            const menuWidth = 370;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const padding = 10;

            const spaceRight = windowWidth - mouseX - padding;
            const spaceLeft = mouseX - padding;
            const spaceBottom = windowHeight - mouseY - padding;
            const spaceTop = mouseY - padding;

            let adjustedX, adjustedY;

            if (spaceRight >= menuWidth || spaceRight >= spaceLeft) {
                adjustedX = Math.min(mouseX + padding, windowWidth - menuWidth - padding);
            } else {
                adjustedX = Math.max(mouseX - menuWidth - padding, padding);
            }

            setMenuVisible(true);

            const maxHeight = Math.max(spaceTop, spaceBottom);

            setTimeout(() => {
                const menuActualHeight = menuRef.current ? menuRef.current.offsetHeight : 0;
                const adjustedHeight = Math.min(maxHeight, menuActualHeight);

                const centerRange = windowHeight * 0.4;
                const centerStart = windowHeight / 2 - centerRange / 2;
                const centerEnd = windowHeight / 2 + centerRange / 2;

                if (mouseY >= centerStart && mouseY <= centerEnd) {
                    const centerY = windowHeight / 2 - adjustedHeight / 2;
                    adjustedY = Math.max(padding, Math.min(centerY, windowHeight - adjustedHeight - padding));
                } else {
                    if (spaceBottom < adjustedHeight) {
                        adjustedY = Math.max(padding, Math.min(mouseY - adjustedHeight - padding, spaceTop - padding));
                    } else {
                        adjustedY = Math.max(padding, Math.min(mouseY + padding, windowHeight - adjustedHeight - padding));
                    }
                }

                setMenuPosition({ x: adjustedX, y: adjustedY });
                setMenuHeight(adjustedHeight);
            }, 0);
        } catch (error) {
            console.error("Error in handleContextMenu:", error);
        }
    };

    const closeMenu = () => {
        setMenuVisible(false);
        document.body.style.overflow = 'auto';
    };

    const viewPageSource = () => {
        const newWindow = window.open(`view-source:${window.location.href}`, '_blank');
        if (newWindow) {
            newWindow.focus();
        }
        closeMenu();
    };

    return (
        <div onContextMenu={handleContextMenu} className={'pin-true-wrapper'}>
            {menuVisible && (
                <StyledContextMenu x={menuPosition.x} y={menuPosition.y}>
                    <div className={'ctx-menu'} ref={menuRef}>
                        <ul className={'default-options'}>
                            <li onClick={() => router.back()} className={canGoBack ? 'active' : ''}>
                                <span>Back</span>
                                <span>Alt + Left Arrow</span>
                            </li>
                            <li onClick={() => router.push(forwardHistory[forwardHistory.length - 1] || pathname)} className={canGoForward ? 'active' : ''}>
                                <span>Forward</span>
                                <span>Alt + Right Arrow</span>
                            </li>
                            <li onClick={() => window.location.reload()}>
                                <span>Reload</span>
                                <span>Ctrl + R</span>
                            </li>
                        </ul>
                        <ul className={'copyright'}>
                            <li>
                                <span>© {new Date().getFullYear()} Maximus Education & Migration.</span>
                                <img height={12} alt={'Maximus Logo'} src={'/images/static/fav.png'}/>
                            </li>
                        </ul>
                        <h6>For Students</h6>
                        <ul className={'page'}>
                            <li>
                                <Link prefetch={true} href={'/courses'}>Courses</Link>
                            </li>
                            <li>
                                <Link prefetch={true}  href={'/universities'}>Universities</Link>
                            </li>
                            <li>
                                <Link prefetch={true}  href={'/schools'}>Schools</Link>
                            </li>

                        </ul>
                        <ul className={'page'}>
                            <p style={{marginBottom: '10px', fontWeight: 500}}>Destinations</p>
                            <li>
                                <Link prefetch={true}  href={'/destination/australia'}>Australia</Link>
                            </li>
                            <li>
                                <Link prefetch={true}  href={'/destination/malaysia'}>Malaysia</Link>
                            </li>
                            <li>
                                <Link prefetch={true}  href={'/destination/united-kingdom'}>United Kingdom</Link>
                            </li>

                        </ul>
                        <ul className={'page okay'}>

                            <li>
                                <Link prefetch={true}  href={'/itelts-pte'}>IELTS & PTE</Link>
                            </li>


                        </ul>
                        <ul className={'credit'}>
                            <li>
                                <a href={'https://dcastalia.com'} target={'_blank'}>
                                    <span>Designed & Developed by Dcastalia</span>
                                    <img alt={'Dcastalia Logo'} src={'/images/static/dc-logo.svg'}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </StyledContextMenu>
            )}
        </div>
    );
};

const StyledContextMenu = styled.div`
    position: fixed;
    top: ${({y}) => y}px;
    left: ${({x}) => x}px;
    background-color: ${text};
    border-radius: 8px;
    border: 1px solid ${text};
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    width: 370px;
    height: ${({menuHeight}) => menuHeight}px; // Use the dynamic menuHeight
    overflow: auto;
    z-index: 9999999999;
    h6 {
        color: ${white};
        padding-left: 25px;
        padding-right: 24px;
        margin-top: 15px;
        font-size: 18px;
    }
    .ctx-menu {
        margin-bottom: 10px;
        ul {
            padding-top: 10px;
         

            p {
                color: ${white};
                padding-left: 25px;
                padding-right: 24px;
            }
            li {
                display: flex;
                justify-content: space-between;
                padding-top: 0;
                padding-bottom:0;
                padding-left: 25px;
                padding-right: 24px;
                background-color: transparent;
                transition: background-color 0.2s ease-in-out;
                cursor: pointer;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }

                span {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: ${white} !important;
                    transition: color 0.2s ease-in-out;
                }

                a {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: ${white};
                    transition: color 0.2s ease-in-out;
                }

            

                &:hover {
                    background-color: ${hover};
                    transition: background-color 0.2s ease-in-out;

                    span {
                        color: #ffffff;
                        transition: color 0.2s ease-in-out;
                    }
                }
            }
        }

        .default-options {
            padding-bottom: 10px;
            li {
                cursor: pointer;
                span {
                    color: ${white} !important;
                }

                &.active {
                    span {
                        color: ${white} !important;
                    }
                }

                &:last-child {
                    span {
                        color: ${white} !important;
                    }
                }

                &:hover {
                    span {
                        color: ${white} !important;
                    }
                }
            }
        }

        .copyright {
            border-top: 1px solid ${softWhite};
            border-bottom: 1px solid ${softWhite};
            padding-bottom: 12px;

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;

                &:hover {
                    background-color: transparent !important;

                    span {
                        color: unset;
                    }
                }
            }
        }

        .page {
            li {
                a {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    transition: all 0.2s ease-in-out;
                }

                &:hover {
                    a {
                        color: #ffffff !important;
                        transition: all 0.2s ease-in-out;
                    }
                }
            }
        }

        .credit {
            border-top: 1px solid ${softWhite};

            li {
                a {
                    width: 100%;
                    display: flex;
                    gap: 10px;

                    span {
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 400;
                        transition: all 0.2s ease-in-out;
                    }

                }

                &:hover {
                    a {
                        color: unset !important;

                        span {
                            color: #ffffff !important;
                            transition: all 0.2s ease-in-out;
                        }
                    }
                }
            }
        }
    }
    
    .okay{
        margin-bottom: 10px;
    }
`;

export default CustomContextMenu;